<template>
    <div>
        <LiefengContent :isBack="true" @backClick="backClick">
            <template v-slot:title>{{ `${$route.query.columnName}子栏目管理` }}</template>
            <template v-slot:toolsbarRight>
                <Form  :label-colon="true" :inline="true" class="search"> 
                    <Button
                        icon="ios-add"
                        type="primary"
                        @click="addChange"
                        style="margin-right: 10px"
                    >新增</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
            </LiefengContent>
             <!--新增修改-->
            <LiefengModal
                :title="titleName"
                :value="contrastStatus"
                @input="contrastFn"
                :fullscreen="false"
                :width="500"
                :height="500"
                >
                <template v-slot:contentarea>
                    <FormAdd ref="form" :titleName="titleName" :showNum="showNum" :formData="formData"></FormAdd>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" style="margin-right:10px" @click="contrastFn(false)">取消</Button>
                    <Button type="primary" @click="contrastSave">确定</Button>
                </template>
                </LiefengModal>
    </div>  
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import FormAdd from './childrens/form'
export default {
    components:{LiefengContent,LiefengTable,LiefengModal,FormAdd},
    data(){
        return{
           // table表格数据
             talbeColumns: [
                   {
                       title: "栏目名称",
                       key: "columnName",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                       title: "栏目编码",
                       key: "sysColumnCode",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                       title: "属性值",
                       key: "extendCode",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                     title: "功能类型",
                     minWidth: 200,
                     align: "center",
                     render: (h, params) => {
                       for (var i = 0; i < this.$refs.form.functionList.length; i++) {
                         if (params.row.functionId == this.$refs.form.functionList[i].functionId) {
                           return h("div", {}, this.$refs.form.functionList[i].functionName)
                         }
                       }
                     }
                   },
                   {
                        title: "操作",
                        align: "center",
                        fixed: "right",
                        width: 180,
                        render: (h, params) => {
                                return h(
                                "div",
                                {
                                    style: {
                                    textAlign: "center"
                                    }
                                },
                                [  
                                    h(
                                    "Dropdown",
                                    {
                                        props: {
                                        transfer: true
                                        }
                                    },
                                    [
                                        h(
                                        "Button",
                                        {
                                            props: {
                                                type: "success",
                                                size: "small",
                                                icon: "ios-arrow-down"
                                            }
                                        },
                                        "操作"
                                        ),
                                        h(
                                        "DropdownMenu",
                                        {
                                            slot: "list"
                                        },
                                        [
                                           h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        this.getDetail(params.row.sysColumnId)
                                                    }
                                                },
                                                style: {
                                                    textAlign: "center"
                                                }
                                            },
                                            "修改"
                                            ),
                                     
                                            h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                    
                                                        this.$Modal.confirm({
                                                        title: "温馨提示",
                                                        content:
                                                            "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                                                            onOk: () => {
                                                                this.deleteRow(params.row.sysColumnId)
                                                            
                                                            }
                                                        });
                                                    }
                                                },
                                                style: {
                                                    textAlign: "center"
                                                }
                                            },
                                            "删除"
                                            ),
                                        ]
                                        )
                                    ]
                                    )
                                ]
                                );
                         }
                        },
             
             ],
             tableData:[],
             loading:false,
             page:1,
             pageSize:20,
             total:0,
             currentPage:0,

            // 新增修改模态框状态
            contrastStatus:false,
            titleName:'',
            showNum:0,
            formData:{},

            // 查询参数
            searchData:{
                systemId:[]
            },
            
            // 存放数字字典接口数据
            functionList:[],
            businessList:[],

            // 查询数据的级联选择器
            cascaderList:[]

        }
    },
    async created() {
       // await this.getDictTye('FUNCTION_TYPE','functionList')
       await this.getDictTye('MENU_BUSINESS','businessList')
       await this.getList()
        
    },
    methods:{
        //顶部返回
        backClick(){
            this.$router.push(`/columnconfigindex?systemId=${this.$route.query.systemId}`)
        },

        // 切换分页的方法
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 点击了新增按钮
        addChange(){
            this.contrastStatus = true   
            this.titleName = '新增子栏目'
            ++this.showNum
             this.formData = {
                    columnType:this.$route.query.columnType,
                    functionType:this.$route.query.functionType,
                    systemId:this.$route.query.systemId,
                    columnName:'',
                    extendCode:''
                }
        },
        // 关闭弹窗的方法
        contrastFn(status){
                 this.contrastStatus = status          
        },
        // 新增修改保存按钮
        contrastSave(){
            this.$refs.form.$refs.form.validate((valid)=>{
                if(valid){
                    console.log(this.formData.systemId);
                    if(!this.formData.systemId || this.formData.systemId.length == 0){
                        this.$Message.warning({
                            content:'请选择系统ID',
                            background:true
                        })
                        return
                    }
                    if(this.titleName == '新增子栏目'){
                        this.formData.parentId = this.$route.query.sysColumnId
                        this.addTreeRow()
                    }  else if(this.titleName == '修改子栏目'){
                        this.updateRow()
                    }   
                }
            })
            
        },   

        // callback级联函数
          loadData (item, callback) {
               item.loading = true
               this.$get('/gx/pc/organization/orgSystem/selectSystemList',{
                    keyword:'',
                    parentId:item.value
                }).then(res=>{
                    if(res.code == 200){
                        let arr = res.dataList.map(item=>{
                            return {
                                value:item.systemId,
                                label:item.appName,
                                parentId:item.parentId,
                                appCode:item.appCode
                            }
                        })
                        item.loading = false
                        item.children = arr
                        callback(item.children)
                    }else{
                        callback([])
                    }
                })
                
         },
        // 接口部分
       async getList(){
            this.loading = true
            await this.$get('/auth/api/auth/pc/systemColumn/selectByPage',{
                page:this.page,
                pageSize:this.pageSize,
                parentId:this.$route.query.sysColumnId,
                systemId:this.$route.query.systemId
            }).then(res=>{
                this.loading = false
                if(res.code == 200){
                    if(res.dataList && res.dataList.length != 0){
                        res.dataList.map(item =>{
                          this.functionList.map(items =>{
                              if(items.dictKey == item.functionType){
                                  item.functionType = items.dictValue
                              }
                          })  
                        })
                    }
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                }else{
                    this.$Message.error({
                        content:'获取子栏目列表失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 数字字典接口
       async getDictTye(dictType,list){
           await this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType
            }).then(res => {
                if(res.code == 200) {
                this[list]= res.dataList
                }
            })
        },
        // 删除接口
        deleteRow(columnId){
            this.$post('/auth/api/auth/pc/systemColumn/deleteById',{
                columnId
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.page = this.currentPage
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'删除失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 新增接口
        addRow(){
            this.$post('/auth/api/auth/pc/systemColumn/add',{
                ...this.formData,
                systemId:this.formData.systemId[this.formData.systemId.length - 1]
            },{'Content-Type':'Application/json'}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                    this.page = 1
                    this.searchData = {}
                    this.contrastStatus = false
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'新增失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 新增子栏目
        addTreeRow(){
            this.$post('/auth/api/auth/pc/systemColumn/add',{
                ...this.formData,
            },{'Content-Type':'Application/json'}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                    this.contrastStatus = false
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'新增失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 获取详情接口
        getDetail(columnId){
            this.$get('/auth/api/auth/pc/systemColumn/selectById',{
                columnId
            }).then(res =>{
                if(res.code == 200){
                    this.formData = res.data
                    this.contrastStatus = true   
                    this.titleName = '修改子栏目'
                    ++this.showNum
                }else{  
                    this.$Message.error({
                        content:'获取栏目详情失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 更新接口
        updateRow(){
            this.$post('/auth/api/auth/pc/systemColumn/update',{
                ...this.formData,
            },{'Content-Type':'Application/json'}).then(res=>{
                if(res.code == 200){
                     this.$Message.success({
                        content:'修改成功',
                        background:true
                    })
                    this.page = this.currentPage
                    this.contrastStatus = false
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'修改失败',
                        background:true
                    })
                    return
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/#toolsbarRight {
  width: 1000%;
  .search {
    width: 100%;
    text-align: right;
    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}
// /deep/.ivu-modal-close {
//   display: none;
// }
/deep/ #modal_contentarea{
        overflow: visible !important;
}
</style>